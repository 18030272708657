import { ApolloClient } from '@apollo/client';

import {
    CreateNewGsItemDocument,
    CreateNewGsItemMutation,
    CreateNewGsItemMutationVariables,
    DeleteGsItemDocument,
    DeleteGsItemMutation,
    DeleteGsItemMutationVariables,
    FilterOptions,
    GetAllGsItemsDocument,
    GetAllGsItemsQuery,
    GetAllGsItemsQueryVariables,
    GetGsItemByIdDocument,
    GetGsItemByIdQuery,
    GetGsItemByIdQueryVariables,
    UpdateExistingItemDocument,
    UpdateExistingItemMutation,
    UpdateExistingItemMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class ItemsHandler extends BaseSDKHandler<
    CreateNewGsItemMutation,
    CreateNewGsItemMutationVariables,
    GetGsItemByIdQuery,
    GetGsItemByIdQueryVariables,
    UpdateExistingItemMutation,
    UpdateExistingItemMutationVariables,
    DeleteGsItemMutation,
    DeleteGsItemMutationVariables,
    GetAllGsItemsQuery,
    GetAllGsItemsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'Items',
            CreateNewGsItemDocument,
            GetGsItemByIdDocument,
            UpdateExistingItemDocument,
            DeleteGsItemDocument,
            GetAllGsItemsDocument
        );
    }

    useItems = (
        options: FilterOptions = {
            limit: 10000,
            page: 0,
        }
    ) => {
        return this.useGetAll(
            { options },
            {
                select: (data) => data?.gsItems,
            }
        );
    };
}

export function createItemsHandler(client: ApolloClient<object>) {
    return new ItemsHandler(client);
}
