import { ApolloClient, ApolloQueryResult, DocumentNode, OperationVariables, TypedDocumentNode } from '@apollo/client';
import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';

export type FetchPolicy = 'cache-first' | 'network-only' | 'cache-only' | 'no-cache' | 'standby';
class ReadOnlyHandler<
    RQuery,
    RQueryVariables extends OperationVariables,
    AQuery,
    AQueryVariables extends OperationVariables
> {
    constructor(
        public client: ApolloClient<object>,
        private entity: string,
        private rDoc: DocumentNode | TypedDocumentNode<RQuery, RQueryVariables>,
        private aDoc: DocumentNode | TypedDocumentNode<AQuery, AQueryVariables>
    ) {}

    useGetOne = <TData = RQuery, TError = Error>(
        variables: RQueryVariables,
        options?: Omit<UseQueryOptions<RQuery, TError, TData, QueryKey>, 'queryKey'> & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: [this.entity, 'get', variables['id']],
            queryFn: async () => {
                const result = await this.client.query<RQuery, RQueryVariables>({
                    query: this.rDoc,
                    variables,
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };

    useGetAll = <TData = AQuery, TError = Error>(
        variables?: AQueryVariables,
        options?: Omit<UseQueryOptions<AQuery, TError, TData, QueryKey>, 'queryKey'> & {
            fetchPolicy?: FetchPolicy;
            refetchOnReconnect?: boolean;
            refetchInterval?: number;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: [this.entity, 'getAll', variables],
            queryFn: async () => {
                const result: ApolloQueryResult<AQuery> = await this.client.query<AQuery, AQueryVariables>({
                    query: this.aDoc,
                    variables,
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };
}

export default ReadOnlyHandler;
