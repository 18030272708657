import { ApolloClient } from '@apollo/client';
import {
    CreateNewDepartmentDocument,
    CreateNewDepartmentMutation,
    CreateNewDepartmentMutationVariables,
    DeleteDepartmentDocument,
    DeleteDepartmentMutation,
    DeleteDepartmentMutationVariables,
    FilterOptions,
    GetAllDepartmentsDocument,
    GetAllDepartmentsQuery,
    GetAllDepartmentsQueryVariables,
    GetDepartmentByIdDocument,
    GetDepartmentByIdQuery,
    GetDepartmentByIdQueryVariables,
    UpdateExistingDepartmentDocument,
    UpdateExistingDepartmentMutation,
    UpdateExistingDepartmentMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class DepartmentsHandler extends BaseSDKHandler<
    CreateNewDepartmentMutation,
    CreateNewDepartmentMutationVariables,
    GetDepartmentByIdQuery,
    GetDepartmentByIdQueryVariables,
    UpdateExistingDepartmentMutation,
    UpdateExistingDepartmentMutationVariables,
    DeleteDepartmentMutation,
    DeleteDepartmentMutationVariables,
    GetAllDepartmentsQuery,
    GetAllDepartmentsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'Departments',
            CreateNewDepartmentDocument,
            GetDepartmentByIdDocument,
            UpdateExistingDepartmentDocument,
            DeleteDepartmentDocument,
            GetAllDepartmentsDocument
        );
    }

    useDepartments = (
        options: FilterOptions = {
            limit: 10000,
            page: 0,
        }
    ) => {
        return this.useGetAll(
            { options },
            {
                select: (data) => data?.departments,
            }
        );
    };
}

export function createDepartmentsHandler(client: ApolloClient<object>) {
    return new DepartmentsHandler(client);
}
