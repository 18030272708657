import { ApolloClient } from '@apollo/client';
import { QueryKey, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import {
    ChangePasswordDocument,
    ChangePasswordMutation,
    ChangePasswordMutationVariables,
    CreateNewUserDocument,
    CreateNewUserMutation,
    CreateNewUserMutationVariables,
    DeleteUserDocument,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    ForgotPasswordDocument,
    GetAllUsersDocument,
    GetAllUsersQuery,
    GetAllUsersQueryVariables,
    GetCurrentUserDocument,
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables,
    GetUserByIdDocument,
    GetUserByIdQuery,
    GetUserByIdQueryVariables,
    ResetPasswordDocument,
    UpdateExistingUserDocument,
    UpdateExistingUserMutation,
    UpdateExistingUserMutationVariables,
    UpdateUserAvatarDocument,
    UpdateUserAvatarMutation,
    UpdateUserAvatarMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler, { FetchPolicy } from './base-handler';

class UsersHandler extends BaseSDKHandler<
    CreateNewUserMutation,
    CreateNewUserMutationVariables,
    GetUserByIdQuery,
    GetUserByIdQueryVariables,
    UpdateExistingUserMutation,
    UpdateExistingUserMutationVariables,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    GetAllUsersQuery,
    GetAllUsersQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'users',
            CreateNewUserDocument,
            GetUserByIdDocument,
            UpdateExistingUserDocument,
            DeleteUserDocument,
            GetAllUsersDocument
        );
    }

    // ======> queries
    useGetCurrentUser = <TData = GetCurrentUserQueryVariables, TError = Error>(
        variables?: GetCurrentUserQueryVariables,
        options?: Omit<UseQueryOptions<GetCurrentUserQuery, TError, TData, QueryKey>, 'queryKey'> & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: ['users', 'getCurrentUser'],
            queryFn: async () => {
                const result = await this.client.query<GetCurrentUserQuery, GetCurrentUserQueryVariables>({
                    query: GetCurrentUserDocument,
                    variables,
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };

    // ======> mutations
    useUpdateUserAvatar = () =>
        useMutation({
            mutationFn: (variables: UpdateUserAvatarMutationVariables) => {
                return this.client.mutate<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>({
                    mutation: UpdateUserAvatarDocument,
                    variables,
                });
            },
        });

    // ======> helpers
    useUser = () => {
        return this.useGetCurrentUser(undefined, {
            fetchPolicy: 'network-only',
            select: (data) => data?.me,
        });
    };

    useUserId = () => {
        return this.useGetCurrentUser(undefined, {
            select: (data) => data?.me?.id,
        });
    };

    useIsLoggedIn = () => {
        return this.useGetCurrentUser(undefined, {
            select: (data) => Boolean(data?.me?.id),
        });
    };

    useUserOrg = () => {
        return this.useGetCurrentUser(undefined, {
            select: (data) => data?.me?.organization,
        });
    };

    useChangePassword = () =>
        useMutation({
            mutationFn: (variables: ChangePasswordMutationVariables) => {
                return this.client.mutate<ChangePasswordMutation, ChangePasswordMutationVariables>({
                    mutation: ChangePasswordDocument,
                    variables,
                });
            },
        });

    useForgotPassword = () =>
        useMutation({
            mutationFn: (variables: { email: string }) => {
                return this.client.mutate({
                    mutation: ForgotPasswordDocument,
                    variables,
                });
            },
        });

    useResetPassword = () => {
        return useMutation({
            mutationFn: (variables: { email: string; code: string; password: string }) => {
                return this.client.mutate({
                    mutation: ResetPasswordDocument,
                    variables,
                });
            },
        });
    };
}

export function createUsersHandler(client: ApolloClient<object>) {
    return new UsersHandler(client);
}
