import { ApolloClient } from '@apollo/client';
import {
    GetAllGsOneCodesDocument,
    GetAllGsOneCodesQuery,
    GetAllGsOneCodesQueryVariables,
    GetGsOneCodeDocument,
    GetGsOneCodeQuery,
    GetGsOneCodeQueryVariables,
} from '../graphql';
import ReadOnlyHandler from './read-only-handler';

export class GsOneCodeHandler extends ReadOnlyHandler<
    GetGsOneCodeQuery,
    GetGsOneCodeQueryVariables,
    GetAllGsOneCodesQuery,
    GetAllGsOneCodesQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(client, 'GsOneCode', GetGsOneCodeDocument, GetAllGsOneCodesDocument);
    }
}

export function createGsOneCodeHandler(client: ApolloClient<object>) {
    return new GsOneCodeHandler(client);
}
