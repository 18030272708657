import { ApolloClient, ApolloQueryResult, FetchPolicy } from '@apollo/client';
import { QueryKey, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import {
    AcceptInviteDocument,
    AcceptInviteMutation,
    AcceptInviteMutationVariables,
    CancelInviteDocument,
    CancelInviteMutation,
    CancelInviteMutationVariables,
    GetInvitesDocument,
    GetInvitesQuery,
    GetInvitesQueryResult,
    InviteOrgOwnerDocument,
    InviteOrgOwnerMutation,
    InviteOrgOwnerMutationVariables,
    InviteUsersDocument,
    InviteUsersMutation,
    InviteUsersMutationVariables,
    ResendInviteDocument,
    ResendInviteMutation,
    ResendInviteMutationVariables,
} from '../graphql';

export class InviteHandler {
    constructor(public client: ApolloClient<object>) {}

    useGetAll = <TData = GetInvitesQuery, TError = Error>(
        options?: Omit<UseQueryOptions<GetInvitesQuery, TError, TData, QueryKey>, 'queryKey'> & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: ['Invites', 'get'],
            queryFn: async () => {
                const result: ApolloQueryResult<GetInvitesQuery> = await this.client.query<
                    GetInvitesQuery,
                    GetInvitesQueryResult
                >({
                    query: GetInvitesDocument,
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };

    useInvite = () =>
        useMutation({
            mutationFn: (variables: InviteUsersMutationVariables) => {
                return this.client.mutate<InviteUsersMutation, InviteUsersMutationVariables>({
                    mutation: InviteUsersDocument,
                    variables,
                });
            },
        });

    useInviteOrgOwner = () =>
        useMutation({
            mutationFn: (variables: InviteOrgOwnerMutationVariables) => {
                return this.client.mutate<InviteOrgOwnerMutation, InviteOrgOwnerMutationVariables>({
                    mutation: InviteOrgOwnerDocument,
                    variables,
                });
            },
        });

    useAcceptInvite = () =>
        useMutation({
            mutationFn: (variables: AcceptInviteMutationVariables) => {
                return this.client.mutate<AcceptInviteMutation, AcceptInviteMutationVariables>({
                    mutation: AcceptInviteDocument,
                    variables,
                });
            },
        });

    useResendInvite = () =>
        useMutation({
            mutationFn: (variables: ResendInviteMutationVariables) => {
                return this.client.mutate<ResendInviteMutation, ResendInviteMutationVariables>({
                    mutation: ResendInviteDocument,
                    variables,
                });
            },
        });

    useCancelInvite = () =>
        useMutation({
            mutationFn: (variables: CancelInviteMutationVariables) => {
                return this.client.mutate<CancelInviteMutation, CancelInviteMutationVariables>({
                    mutation: CancelInviteDocument,
                    variables,
                });
            },
        });
}

export function createInvitesHandler(client: ApolloClient<object>) {
    return new InviteHandler(client);
}
