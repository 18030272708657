import { ApolloClient } from '@apollo/client';
import { useMutation } from '@tanstack/react-query';
import {
    CreateNewOrganizationDocument,
    CreateNewOrganizationMutation,
    CreateNewOrganizationMutationVariables,
    DeleteOrganizationDocument,
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables,
    GetAllOrganizationsDocument,
    GetAllOrganizationsQuery,
    GetAllOrganizationsQueryVariables,
    GetOrganizationByIdDocument,
    GetOrganizationByIdQuery,
    GetOrganizationByIdQueryVariables,
    UpdateExistingOrganizationDocument,
    UpdateExistingOrganizationMutation,
    UpdateExistingOrganizationMutationVariables,
    UpdateOrganizationLogoDocument,
    UpdateOrganizationLogoMutation,
    UpdateOrganizationLogoMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class OrganizationsHandler extends BaseSDKHandler<
    CreateNewOrganizationMutation,
    CreateNewOrganizationMutationVariables,
    GetOrganizationByIdQuery,
    GetOrganizationByIdQueryVariables,
    UpdateExistingOrganizationMutation,
    UpdateExistingOrganizationMutationVariables,
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables,
    GetAllOrganizationsQuery,
    GetAllOrganizationsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'orgs',
            CreateNewOrganizationDocument,
            GetOrganizationByIdDocument,
            UpdateExistingOrganizationDocument,
            DeleteOrganizationDocument,
            GetAllOrganizationsDocument
        );
    }

    // ======> mutations
    useUpdateOrganizationLogo = () =>
        useMutation({
            mutationFn: (variables: UpdateOrganizationLogoMutationVariables) => {
                return this.client.mutate<UpdateOrganizationLogoMutation, UpdateOrganizationLogoMutationVariables>({
                    mutation: UpdateOrganizationLogoDocument,
                    variables,
                });
            },
        });

    // ======> helpers
    useOrg = (orgId: string) => {
        return this.useGetOne(
            {
                id: orgId,
            },
            {
                select: (data) => data?.Organization,
                enabled: !!orgId,
            }
        );
    };
}
export function createOrganizationsHandler(client: ApolloClient<object>) {
    return new OrganizationsHandler(client);
}
