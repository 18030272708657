import { ApolloClient } from '@apollo/client';
import {
    CreateSegmentDocument,
    CreateSegmentMutation,
    CreateSegmentMutationVariables,
    DeleteSegmentDocument,
    DeleteSegmentMutation,
    DeleteSegmentMutationVariables,
    GetAllSegmentsDocument,
    GetAllSegmentsQuery,
    GetAllSegmentsQueryVariables,
    GetSegmentByIdDocument,
    GetSegmentByIdQuery,
    GetSegmentByIdQueryVariables,
    UpdateSegmentDocument,
    UpdateSegmentMutation,
    UpdateSegmentMutationVariables,
} from '../graphql';
import BaseSDKHandler from './base-handler';

export class SegmentsHandler extends BaseSDKHandler<
    CreateSegmentMutation,
    CreateSegmentMutationVariables,
    GetSegmentByIdQuery,
    GetSegmentByIdQueryVariables,
    UpdateSegmentMutation,
    UpdateSegmentMutationVariables,
    DeleteSegmentMutation,
    DeleteSegmentMutationVariables,
    GetAllSegmentsQuery,
    GetAllSegmentsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'Segments',
            CreateSegmentDocument,
            GetSegmentByIdDocument,
            UpdateSegmentDocument,
            DeleteSegmentDocument,
            GetAllSegmentsDocument
        );
    }
}

export function createSegmentHandler(client: ApolloClient<object>) {
    return new SegmentsHandler(client);
}
