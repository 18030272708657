import { ApolloClient } from '@apollo/client';
import {
    CreateNewCustomerRegionDocument,
    CreateNewCustomerRegionMutation,
    CreateNewCustomerRegionMutationVariables,
    DeleteCustomerRegionDocument,
    DeleteCustomerRegionMutation,
    DeleteCustomerRegionMutationVariables,
    FilterOptions,
    GetAllCustomerRegionsDocument,
    GetAllCustomerRegionsQuery,
    GetAllCustomerRegionsQueryVariables,
    GetCustomerRegionByIdDocument,
    GetCustomerRegionByIdQuery,
    GetCustomerRegionByIdQueryVariables,
    UpdateExistingCustomerRegionDocument,
    UpdateExistingCustomerRegionMutation,
    UpdateExistingCustomerRegionMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class CustomerRegionsHandler extends BaseSDKHandler<
    CreateNewCustomerRegionMutation,
    CreateNewCustomerRegionMutationVariables,
    GetCustomerRegionByIdQuery,
    GetCustomerRegionByIdQueryVariables,
    UpdateExistingCustomerRegionMutation,
    UpdateExistingCustomerRegionMutationVariables,
    DeleteCustomerRegionMutation,
    DeleteCustomerRegionMutationVariables,
    GetAllCustomerRegionsQuery,
    GetAllCustomerRegionsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'CustomerRegions',
            CreateNewCustomerRegionDocument,
            GetCustomerRegionByIdDocument,
            UpdateExistingCustomerRegionDocument,
            DeleteCustomerRegionDocument,
            GetAllCustomerRegionsDocument
        );
    }

    useCustomerRegions = (
        options: FilterOptions = {
            limit: 10000,
            page: 0,
        }
    ) => {
        return this.useGetAll(
            { options },
            {
                select: (data) => data?.customerRegions,
            }
        );
    };
}

export function createCustomerRegionsHandler(client: ApolloClient<object>) {
    return new CustomerRegionsHandler(client);
}
