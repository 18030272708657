import { ApolloClient } from '@apollo/client';
import {
    GetGpcSegmentDocument,
    GetGpcSegmentQuery,
    GetGpcSegmentQueryVariables,
    GetGpcSegmentsDocument,
    GetGpcSegmentsQuery,
    GetGpcSegmentsQueryVariables,
} from '../graphql/graphql-schema';
import ReadOnlyHandler from './read-only-handler';

class GpcSegmentsHandler extends ReadOnlyHandler<
    GetGpcSegmentQuery,
    GetGpcSegmentQueryVariables,
    GetGpcSegmentsQuery,
    GetGpcSegmentsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(client, 'GpcSegments', GetGpcSegmentDocument, GetGpcSegmentsDocument);
    }
}

export function createGpcSegmentsHandler(client: ApolloClient<object>) {
    return new GpcSegmentsHandler(client);
}
