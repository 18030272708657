import { ApolloClient } from '@apollo/client';

import { useMutation } from '@tanstack/react-query';
import {
    AddItemToEachDocument,
    AddItemToEachMutationVariables,
    AppendDocumentColumnMutation,
    CreateNewEachDocument,
    CreateNewEachMutation,
    CreateNewEachMutationVariables,
    DeleteEachDocument,
    DeleteEachMutation,
    DeleteEachMutationVariables,
    FilterOptions,
    GetAllEachesDocument,
    GetAllEachesQuery,
    GetAllEachesQueryVariables,
    GetEachByIdDocument,
    GetEachByIdQuery,
    GetEachByIdQueryVariables,
    UpdateExistingEachDocument,
    UpdateExistingEachMutation,
    UpdateExistingEachMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class EachesHandler extends BaseSDKHandler<
    CreateNewEachMutation,
    CreateNewEachMutationVariables,
    GetEachByIdQuery,
    GetEachByIdQueryVariables,
    UpdateExistingEachMutation,
    UpdateExistingEachMutationVariables,
    DeleteEachMutation,
    DeleteEachMutationVariables,
    GetAllEachesQuery,
    GetAllEachesQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'Eaches',
            CreateNewEachDocument,
            GetEachByIdDocument,
            UpdateExistingEachDocument,
            DeleteEachDocument,
            GetAllEachesDocument
        );
    }

    useEaches = (
        options: FilterOptions = {
            limit: 10000,
            page: 0,
        }
    ) => {
        return this.useGetAll(
            { options },
            {
                select: (data) => data?.eaches,
            }
        );
    };

    useAddItemToEach = () => {
        return useMutation({
            mutationFn: (variables: AddItemToEachMutationVariables) => {
                return this.client.mutate<AppendDocumentColumnMutation, AddItemToEachMutationVariables>({
                    mutation: AddItemToEachDocument,
                    variables,
                });
            },
        });
    };
}

export function createEachesHandler(client: ApolloClient<object>) {
    return new EachesHandler(client);
}
