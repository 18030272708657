import { ApolloClient } from '@apollo/client';
import {
    GetGpcVersionDocument,
    GetGpcVersionQuery,
    GetGpcVersionQueryVariables,
    GetGpcVersionsDocument,
    GetGpcVersionsQuery,
    GetGpcVersionsQueryVariables,
} from '../graphql/graphql-schema';
import ReadOnlyHandler from './read-only-handler';

class GpcVersionsHandler extends ReadOnlyHandler<
    GetGpcVersionQuery,
    GetGpcVersionQueryVariables,
    GetGpcVersionsQuery,
    GetGpcVersionsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(client, 'GpcVersions', GetGpcVersionDocument, GetGpcVersionsDocument);
    }
}

export function createGpcVersionsHandler(client: ApolloClient<object>) {
    return new GpcVersionsHandler(client);
}
