import { ApolloClient } from '@apollo/client';
import {
    GetGpcBrickAttributeDocument,
    GetGpcBrickAttributeQuery,
    GetGpcBrickAttributeQueryVariables,
    GetGpcBrickAttributesDocument,
    GetGpcBrickAttributesQuery,
    GetGpcBrickAttributesQueryVariables,
} from '../graphql/graphql-schema';
import ReadOnlyHandler from './read-only-handler';

class GpcBrickAttributesHandler extends ReadOnlyHandler<
    GetGpcBrickAttributeQuery,
    GetGpcBrickAttributeQueryVariables,
    GetGpcBrickAttributesQuery,
    GetGpcBrickAttributesQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(client, 'GpcBrickAttributes', GetGpcBrickAttributeDocument, GetGpcBrickAttributesDocument);
    }
}

export function createGpcBrickAttributesHandler(client: ApolloClient<object>) {
    return new GpcBrickAttributesHandler(client);
}
