import { ApolloClient } from '@apollo/client';
import {
    CreateNewIntegrationLogDocument,
    CreateNewIntegrationLogMutation,
    CreateNewIntegrationLogMutationVariables,
    DeleteIntegrationLogDocument,
    DeleteIntegrationLogMutation,
    DeleteIntegrationLogMutationVariables,
    GetAllIntegrationLogsDocument,
    GetAllIntegrationLogsQuery,
    GetAllIntegrationLogsQueryVariables,
    GetIntegrationLogByIdDocument,
    GetIntegrationLogByIdQuery,
    GetIntegrationLogByIdQueryVariables,
    UpdateExistingIntegrationLogDocument,
    UpdateExistingIntegrationLogMutation,
    UpdateExistingIntegrationLogMutationVariables,
} from '../graphql';
import BaseSDKHandler from './base-handler';

class IntegrationLogsHandler extends BaseSDKHandler<
    CreateNewIntegrationLogMutation,
    CreateNewIntegrationLogMutationVariables,
    GetIntegrationLogByIdQuery,
    GetIntegrationLogByIdQueryVariables,
    UpdateExistingIntegrationLogMutation,
    UpdateExistingIntegrationLogMutationVariables,
    DeleteIntegrationLogMutation,
    DeleteIntegrationLogMutationVariables,
    GetAllIntegrationLogsQuery,
    GetAllIntegrationLogsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'integration-logs',
            CreateNewIntegrationLogDocument,
            GetIntegrationLogByIdDocument,
            UpdateExistingIntegrationLogDocument,
            DeleteIntegrationLogDocument,
            GetAllIntegrationLogsDocument
        );
    }
}

export function createIntegrationLogsHandler(client: ApolloClient<object>) {
    return new IntegrationLogsHandler(client);
}
