import { ApolloClient } from '@apollo/client';
import {
    CreateNewOwningEntityDocument,
    CreateNewOwningEntityMutation,
    CreateNewOwningEntityMutationVariables,
    DeleteOwningEntityDocument,
    DeleteOwningEntityMutation,
    DeleteOwningEntityMutationVariables,
    FilterOptions,
    GetAllOwningEntitiesDocument,
    GetAllOwningEntitiesQuery,
    GetAllOwningEntitiesQueryVariables,
    GetOwningEntityByIdDocument,
    GetOwningEntityByIdQuery,
    GetOwningEntityByIdQueryVariables,
    UpdateExistingOwningEntityDocument,
    UpdateExistingOwningEntityMutation,
    UpdateExistingOwningEntityMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class OwningEntitiesHandler extends BaseSDKHandler<
    CreateNewOwningEntityMutation,
    CreateNewOwningEntityMutationVariables,
    GetOwningEntityByIdQuery,
    GetOwningEntityByIdQueryVariables,
    UpdateExistingOwningEntityMutation,
    UpdateExistingOwningEntityMutationVariables,
    DeleteOwningEntityMutation,
    DeleteOwningEntityMutationVariables,
    GetAllOwningEntitiesQuery,
    GetAllOwningEntitiesQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'OwningEntitys',
            CreateNewOwningEntityDocument,
            GetOwningEntityByIdDocument,
            UpdateExistingOwningEntityDocument,
            DeleteOwningEntityDocument,
            GetAllOwningEntitiesDocument
        );
    }

    useOwningEntitys = (
        options: FilterOptions = {
            limit: 10000,
            page: 0,
        }
    ) => {
        return this.useGetAll(
            { options },
            {
                select: (data) => data?.owningEntities,
            }
        );
    };
}

export function createOwningEntitiesHandler(client: ApolloClient<object>) {
    return new OwningEntitiesHandler(client);
}
