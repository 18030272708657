import { ApolloClient } from '@apollo/client';
import { useMutation } from '@tanstack/react-query';

import {
    AddEachToLogisticUnitDocument,
    AddEachToLogisticUnitMutation,
    AddEachToLogisticUnitMutationVariables,
    AddLogisticUnitToLogisticUnitDocument,
    AddLogisticUnitToLogisticUnitMutation,
    AddLogisticUnitToLogisticUnitMutationVariables,
    AddPackToLogisticUnitDocument,
    AddPackToLogisticUnitMutation,
    AddPackToLogisticUnitMutationVariables,
    CreateNewLogisticUnitDocument,
    CreateNewLogisticUnitMutation,
    CreateNewLogisticUnitMutationVariables,
    DeleteLogisticUnitDocument,
    DeleteLogisticUnitMutation,
    DeleteLogisticUnitMutationVariables,
    FilterOptions,
    GetAllLogisticUnitsDocument,
    GetAllLogisticUnitsQuery,
    GetAllLogisticUnitsQueryVariables,
    GetLogisticUnitByIdDocument,
    GetLogisticUnitByIdQuery,
    GetLogisticUnitByIdQueryVariables,
    UpdateExistingLogisticUnitDocument,
    UpdateExistingLogisticUnitMutation,
    UpdateExistingLogisticUnitMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class LogisticUnitsHandler extends BaseSDKHandler<
    CreateNewLogisticUnitMutation,
    CreateNewLogisticUnitMutationVariables,
    GetLogisticUnitByIdQuery,
    GetLogisticUnitByIdQueryVariables,
    UpdateExistingLogisticUnitMutation,
    UpdateExistingLogisticUnitMutationVariables,
    DeleteLogisticUnitMutation,
    DeleteLogisticUnitMutationVariables,
    GetAllLogisticUnitsQuery,
    GetAllLogisticUnitsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'LogisticUnits',
            CreateNewLogisticUnitDocument,
            GetLogisticUnitByIdDocument,
            UpdateExistingLogisticUnitDocument,
            DeleteLogisticUnitDocument,
            GetAllLogisticUnitsDocument
        );
    }

    useLogisticUnits = (
        options: FilterOptions = {
            limit: 10000,
            page: 0,
        }
    ) => {
        return this.useGetAll(
            { options },
            {
                select: (data) => data?.logisticUnits,
            }
        );
    };

    useAddEachToLogisticUnit = () => {
        return useMutation({
            mutationFn: (variables: AddEachToLogisticUnitMutationVariables) => {
                return this.client.mutate<AddEachToLogisticUnitMutation, AddEachToLogisticUnitMutationVariables>({
                    mutation: AddEachToLogisticUnitDocument,
                    variables,
                });
            },
        });
    };

    useAddPackToLogisticUnit = () => {
        return useMutation({
            mutationFn: (variables: AddPackToLogisticUnitMutationVariables) => {
                return this.client.mutate<AddPackToLogisticUnitMutation, AddPackToLogisticUnitMutationVariables>({
                    mutation: AddPackToLogisticUnitDocument,
                    variables,
                });
            },
        });
    };

    useAddLogisticUnitToLogisticUnit = () => {
        return useMutation({
            mutationFn: (variables: AddLogisticUnitToLogisticUnitMutationVariables) => {
                return this.client.mutate<
                    AddLogisticUnitToLogisticUnitMutation,
                    AddLogisticUnitToLogisticUnitMutationVariables
                >({
                    mutation: AddLogisticUnitToLogisticUnitDocument,
                    variables,
                });
            },
        });
    };
}

export function createLogisticUnitsHandler(client: ApolloClient<object>) {
    return new LogisticUnitsHandler(client);
}
