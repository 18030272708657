import { ApolloClient } from '@apollo/client';
import { AxiosInstance } from 'axios';
import * as React from 'react';
import { ConnectEntities } from './graphql';
import {
    createCustomerTemplatesHandler,
    createCustomersHandler,
    createDocumentsHandler,
    createFilesHandler,
    createImportsHandler,
    createIntegrationLogsHandler,
    createIntegrationRecordsHandler,
    createOrganizationsHandler,
    createProductsHandler,
    createReadOnlyHandler,
    createSegmentHandler,
    createUsagesHandler,
    createUsersHandler,
} from './handlers';
import { createBrandsHandler } from './handlers/brands';
import { createCustomerRegionsHandler } from './handlers/customer-regions';
import { createDeliverTosHandler } from './handlers/deliver-tos';
import { createDepartmentsHandler } from './handlers/departments';
import { createEachesHandler } from './handlers/eaches';
import { createGeneratedDocumentsHandler } from './handlers/generated-document';
import { createGpcBrickAttributeValuesHandler } from './handlers/gpc-brick-attribute-values';
import { createGpcBrickAttributesHandler } from './handlers/gpc-brick-attributes';
import { createGpcBricksHandler } from './handlers/gpc-bricks';
import { createGpcClassesHandler } from './handlers/gpc-classes';
import { createGpcFamiliesHandler } from './handlers/gpc-families';
import { createGpcSegmentsHandler } from './handlers/gpc-segments';
import { createGpcVersionsHandler } from './handlers/gpc-versions';
import { createGsOneCodeHandler } from './handlers/gs-one-code';
import { createInvitesHandler } from './handlers/invite';
import { createItemsHandler } from './handlers/items';
import { createLogisticUnitsHandler } from './handlers/logistic-units';
import { createMappingSuggestionsHandler } from './handlers/mapping-suggestions';
import { createOwningEntitiesHandler } from './handlers/owning-entities';
import { createPacksHandler } from './handlers/packs';
import { createRegionsHandler } from './handlers/regions';
import { createSubBrandsHandler } from './handlers/subbrands';
import { createSubsidiariesHandler } from './handlers/subsidiaries';
import { createUomHandler } from './handlers/uom';

export class SDK {
    private client: ApolloClient<object>;
    public customerTemplates: ReturnType<typeof createCustomerTemplatesHandler>;
    public customers: ReturnType<typeof createCustomersHandler>;
    public documents: ReturnType<typeof createDocumentsHandler>;
    public files: ReturnType<typeof createFilesHandler>;
    public generatedDocuments: ReturnType<typeof createGeneratedDocumentsHandler>;
    public organizations: ReturnType<typeof createOrganizationsHandler>;
    public usages: ReturnType<typeof createUsagesHandler>;
    public users: ReturnType<typeof createUsersHandler>;
    public imports: ReturnType<typeof createImportsHandler>;
    public integrationLogs: ReturnType<typeof createIntegrationLogsHandler>;
    public integrationRecords: ReturnType<typeof createIntegrationRecordsHandler>;
    public products: ReturnType<typeof createProductsHandler>;
    public readOnly: ReturnType<typeof createReadOnlyHandler>;
    public invites: ReturnType<typeof createInvitesHandler>;
    public mappingSuggestions: ReturnType<typeof createMappingSuggestionsHandler>;
    public subsidiaries: ReturnType<typeof createSubsidiariesHandler>;
    public departments: ReturnType<typeof createDepartmentsHandler>;
    public regions: ReturnType<typeof createRegionsHandler>;
    public customerRegions: ReturnType<typeof createCustomerRegionsHandler>;
    public owningEntities: ReturnType<typeof createOwningEntitiesHandler>;
    public deliverTos: ReturnType<typeof createDeliverTosHandler>;
    public brands: ReturnType<typeof createBrandsHandler>;
    public subbrands: ReturnType<typeof createSubBrandsHandler>;

    public gpcVersions: ReturnType<typeof createGpcVersionsHandler>;
    public gpcSegments: ReturnType<typeof createGpcSegmentsHandler>;
    public gpcFamilies: ReturnType<typeof createGpcFamiliesHandler>;
    public gpcClasses: ReturnType<typeof createGpcClassesHandler>;
    public gpcBricks: ReturnType<typeof createGpcBricksHandler>;
    public gpcBrickAttributes: ReturnType<typeof createGpcBrickAttributesHandler>;
    public gpcBrickAttributeValues: ReturnType<typeof createGpcBrickAttributeValuesHandler>;

    public items: ReturnType<typeof createItemsHandler>;
    public packs: ReturnType<typeof createPacksHandler>;
    public eaches: ReturnType<typeof createEachesHandler>;
    public logisticUnits: ReturnType<typeof createLogisticUnitsHandler>;

    public segments: ReturnType<typeof createSegmentHandler>;
    public gsOneCodes: ReturnType<typeof createGsOneCodeHandler>;
    public uom: ReturnType<typeof createUomHandler>;

    constructor(private apolloClient: ApolloClient<object>, public axiosClient: AxiosInstance) {
        this.client = apolloClient;
        this.customerTemplates = createCustomerTemplatesHandler(this.client);
        this.customers = createCustomersHandler(this.client);
        this.documents = createDocumentsHandler(this.client);
        this.files = createFilesHandler(this.client);
        this.generatedDocuments = createGeneratedDocumentsHandler(this.client);
        this.organizations = createOrganizationsHandler(this.client);
        this.usages = createUsagesHandler(this.client);
        this.users = createUsersHandler(this.client);
        this.imports = createImportsHandler(this.client);
        this.integrationLogs = createIntegrationLogsHandler(this.client);
        this.integrationRecords = createIntegrationRecordsHandler(this.client);
        this.products = createProductsHandler(this.client);
        this.readOnly = createReadOnlyHandler(this.client);
        this.invites = createInvitesHandler(this.client);
        this.mappingSuggestions = createMappingSuggestionsHandler(this.client);
        this.subsidiaries = createSubsidiariesHandler(this.client);
        this.departments = createDepartmentsHandler(this.client);
        this.regions = createRegionsHandler(this.client);
        this.customerRegions = createCustomerRegionsHandler(this.client);
        this.owningEntities = createOwningEntitiesHandler(this.client);
        this.deliverTos = createDeliverTosHandler(this.client);
        this.brands = createBrandsHandler(this.client);
        this.subbrands = createSubBrandsHandler(this.client);
        this.gpcVersions = createGpcVersionsHandler(this.client);
        this.gpcSegments = createGpcSegmentsHandler(this.client);
        this.gpcFamilies = createGpcFamiliesHandler(this.client);
        this.gpcClasses = createGpcClassesHandler(this.client);
        this.gpcBricks = createGpcBricksHandler(this.client);
        this.gpcBrickAttributes = createGpcBrickAttributesHandler(this.client);
        this.gpcBrickAttributeValues = createGpcBrickAttributeValuesHandler(this.client);
        this.items = createItemsHandler(this.client);
        this.packs = createPacksHandler(this.client);
        this.eaches = createEachesHandler(this.client);
        this.logisticUnits = createLogisticUnitsHandler(this.client);
        this.segments = createSegmentHandler(this.client);
        this.gsOneCodes = createGsOneCodeHandler(this.client);
        this.uom = createUomHandler(this.client);
    }
}

const SDKContext = React.createContext<SDK | null>(null);

export interface SDKProviderProps {
    apolloClient: ApolloClient<object>;
    axiosClient: AxiosInstance;
    children: React.ReactNode;
}
export const SDKProvider = ({ children, apolloClient, axiosClient }: SDKProviderProps) => {
    const sdk = new SDK(apolloClient, axiosClient);
    return <SDKContext.Provider value={sdk}>{children}</SDKContext.Provider>;
};

export const useSDK = () => {
    const sdk = React.useContext(SDKContext);
    if (!sdk) {
        throw new Error('useSDK must be used within a SDKProvider');
    }
    return sdk;
};

type ReadOnlyCheckResponse = {
    data: boolean | undefined;
    isLoading: boolean;
    isError: boolean;
    error: Error | null;
};

export const useIsReadOnly = (entity: ConnectEntities): ReadOnlyCheckResponse => {
    const sdk = useSDK();
    if (!sdk) {
        throw new Error('useSDK must be used within a SDKProvider');
    }
    const { data, isLoading, isError, error } = sdk.readOnly.useCheck({});
    const response: boolean | undefined = data?.check.info[entity];
    return { data: response, isLoading, isError, error };
};

export * from './graphql';
