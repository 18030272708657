import { ApolloClient } from '@apollo/client';

import {
    CreateNewBrandDocument,
    CreateNewBrandMutation,
    CreateNewBrandMutationVariables,
    DeleteBrandDocument,
    DeleteBrandMutation,
    DeleteBrandMutationVariables,
    FilterOptions,
    GetAllBrandsDocument,
    GetAllBrandsQuery,
    GetAllBrandsQueryVariables,
    GetBrandByIdDocument,
    GetBrandByIdQuery,
    GetBrandByIdQueryVariables,
    UpdateExistingBrandDocument,
    UpdateExistingBrandMutation,
    UpdateExistingBrandMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class BrandsHandler extends BaseSDKHandler<
    CreateNewBrandMutation,
    CreateNewBrandMutationVariables,
    GetBrandByIdQuery,
    GetBrandByIdQueryVariables,
    UpdateExistingBrandMutation,
    UpdateExistingBrandMutationVariables,
    DeleteBrandMutation,
    DeleteBrandMutationVariables,
    GetAllBrandsQuery,
    GetAllBrandsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'Brands',
            CreateNewBrandDocument,
            GetBrandByIdDocument,
            UpdateExistingBrandDocument,
            DeleteBrandDocument,
            GetAllBrandsDocument
        );
    }

    useBrands = (
        options: FilterOptions = {
            limit: 10000,
            page: 0,
        }
    ) => {
        return this.useGetAll(
            { options },
            {
                select: (data) => data?.brands,
            }
        );
    };
}

export function createBrandsHandler(client: ApolloClient<object>) {
    return new BrandsHandler(client);
}
