import { ApolloClient } from '@apollo/client';
import {
    CreateNewCustomerTemplateDocument,
    CreateNewCustomerTemplateMutation,
    CreateNewCustomerTemplateMutationVariables,
    DeleteCustomerTemplateDocument,
    DeleteCustomerTemplateMutation,
    DeleteCustomerTemplateMutationVariables,
    FilterOptions,
    GetAllCustomerTemplatesDocument,
    GetAllCustomerTemplatesQuery,
    GetAllCustomerTemplatesQueryVariables,
    GetCustomerByIdDocument,
    GetCustomerTemplateByIdQuery,
    GetCustomerTemplateByIdQueryVariables,
    UpdateExistingCustomerTemplateDocument,
    UpdateExistingCustomerTemplateMutation,
    UpdateExistingCustomerTemplateMutationVariables,
} from '../graphql';
import BaseSDKHandler from './base-handler';

class CustomerTemplatesHandler extends BaseSDKHandler<
    CreateNewCustomerTemplateMutation,
    CreateNewCustomerTemplateMutationVariables,
    GetCustomerTemplateByIdQuery,
    GetCustomerTemplateByIdQueryVariables,
    UpdateExistingCustomerTemplateMutation,
    UpdateExistingCustomerTemplateMutationVariables,
    DeleteCustomerTemplateMutation,
    DeleteCustomerTemplateMutationVariables,
    GetAllCustomerTemplatesQuery,
    GetAllCustomerTemplatesQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'customerTemplates',
            CreateNewCustomerTemplateDocument,
            GetCustomerByIdDocument,
            UpdateExistingCustomerTemplateDocument,
            DeleteCustomerTemplateDocument,
            GetAllCustomerTemplatesDocument
        );
    }

    // ======> helpers
    useCustomerTemplates = (
        options: FilterOptions = {
            limit: 1000,
            page: 0,
        },
        refetchInterval?: number
    ) => {
        return this.useGetAll(
            { options },
            {
                fetchPolicy: 'network-only',
                refetchOnReconnect: true,
                refetchInterval,
                select: (data) => data?.customerTemplates,
            }
        );
    };
}

export function createCustomerTemplatesHandler(client: ApolloClient<object>): CustomerTemplatesHandler {
    return new CustomerTemplatesHandler(client);
}
