import { ApolloClient } from '@apollo/client';
import {
    CreateNewCustomerDocument,
    CreateNewCustomerMutation,
    CreateNewCustomerMutationVariables,
    DeleteCustomerDocument,
    DeleteCustomerMutation,
    DeleteCustomerMutationVariables,
    FilterOptions,
    GetAllCustomersDocument,
    GetAllCustomersQuery,
    GetAllCustomersQueryVariables,
    GetCustomerByIdDocument,
    GetCustomerByIdQuery,
    GetCustomerByIdQueryVariables,
    UpdateExistingCustomerDocument,
    UpdateExistingCustomerMutation,
    UpdateExistingCustomerMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class CustomersHandler extends BaseSDKHandler<
    CreateNewCustomerMutation,
    CreateNewCustomerMutationVariables,
    GetCustomerByIdQuery,
    GetCustomerByIdQueryVariables,
    UpdateExistingCustomerMutation,
    UpdateExistingCustomerMutationVariables,
    DeleteCustomerMutation,
    DeleteCustomerMutationVariables,
    GetAllCustomersQuery,
    GetAllCustomersQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'customers',
            CreateNewCustomerDocument,
            GetCustomerByIdDocument,
            UpdateExistingCustomerDocument,
            DeleteCustomerDocument,
            GetAllCustomersDocument
        );
    }

    // ======> helpers
    useCustomers = (
        options: FilterOptions = {
            limit: 10000,
            page: 0,
        }
    ) => {
        return this.useGetAll(
            { options },
            {
                select: (data) => data?.customers,
            }
        );
    };
}

export function createCustomersHandler(client: ApolloClient<object>) {
    return new CustomersHandler(client);
}
