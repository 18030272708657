import { ApolloClient, ApolloQueryResult, FetchPolicy } from '@apollo/client';
import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
    GetAllUsagesDocument,
    GetAllUsagesQuery,
    GetAllUsagesQueryVariables,
    GetUsageByIdDocument,
    GetUsageByIdQuery,
    GetUsageByIdQueryVariables,
} from '../graphql/graphql-schema';

class UsagesHandler {
    constructor(public client: ApolloClient<object>) {}

    // ======> queries
    useGetOne = <TData = GetUsageByIdQuery, TError = Error>(
        variables: GetUsageByIdQueryVariables,
        options?: Omit<UseQueryOptions<GetUsageByIdQuery, TError, TData, QueryKey>, 'queryKey'> & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: ['usages', 'get', variables['id']],
            queryFn: async () => {
                const result: ApolloQueryResult<GetUsageByIdQuery> = await this.client.query<
                    GetUsageByIdQuery,
                    GetUsageByIdQueryVariables
                >({
                    query: GetUsageByIdDocument,
                    variables,
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };

    useGetAll = <TData = GetAllUsagesQuery, TError = Error>(
        options?: Omit<UseQueryOptions<GetAllUsagesQuery, TError, TData, QueryKey>, 'queryKey'> & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: ['usages', 'get'],
            queryFn: async () => {
                const result: ApolloQueryResult<GetAllUsagesQuery> = await this.client.query<
                    GetAllUsagesQuery,
                    GetAllUsagesQueryVariables
                >({
                    query: GetAllUsagesDocument,
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };
}

export function createUsagesHandler(client: ApolloClient<object>) {
    return new UsagesHandler(client);
}
