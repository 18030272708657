import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { SearchProductsDocument, SearchProductsQuery, SearchProductsQueryVariables } from '../graphql/graphql-schema';
import { FetchPolicy } from './read-only-handler';

class ProductsHandler {
    constructor(private client: ApolloClient<object>) {}

    useSearchProducts = <TData = SearchProductsQuery, TError = Error>(
        variables: SearchProductsQueryVariables,
        options?: Omit<UseQueryOptions<SearchProductsQuery, TError, TData>, 'queryKey'> & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: ['searchproducts', variables],
            queryFn: async () => {
                const result: ApolloQueryResult<SearchProductsQuery> = await this.client.query<
                    SearchProductsQuery,
                    SearchProductsQueryVariables
                >({
                    query: SearchProductsDocument,
                    variables,
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });

        return { ...queryInfo };
    };
}

export function createProductsHandler(client: ApolloClient<object>) {
    return new ProductsHandler(client);
}
