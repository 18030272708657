import { ApolloClient } from '@apollo/client';
import {
    CreateNewRegionDocument,
    CreateNewRegionMutation,
    CreateNewRegionMutationVariables,
    DeleteRegionDocument,
    DeleteRegionMutation,
    DeleteRegionMutationVariables,
    FilterOptions,
    GetAllRegionsDocument,
    GetAllRegionsQuery,
    GetAllRegionsQueryVariables,
    GetRegionByIdDocument,
    GetRegionByIdQuery,
    GetRegionByIdQueryVariables,
    UpdateExistingRegionDocument,
    UpdateExistingRegionMutation,
    UpdateExistingRegionMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class RegionsHandler extends BaseSDKHandler<
    CreateNewRegionMutation,
    CreateNewRegionMutationVariables,
    GetRegionByIdQuery,
    GetRegionByIdQueryVariables,
    UpdateExistingRegionMutation,
    UpdateExistingRegionMutationVariables,
    DeleteRegionMutation,
    DeleteRegionMutationVariables,
    GetAllRegionsQuery,
    GetAllRegionsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'Regions',
            CreateNewRegionDocument,
            GetRegionByIdDocument,
            UpdateExistingRegionDocument,
            DeleteRegionDocument,
            GetAllRegionsDocument
        );
    }

    useRegions = (
        options: FilterOptions = {
            limit: 10000,
            page: 0,
        }
    ) => {
        return this.useGetAll(
            { options },
            {
                select: (data) => data?.regions,
            }
        );
    };
}

export function createRegionsHandler(client: ApolloClient<object>) {
    return new RegionsHandler(client);
}
