import { ApolloClient } from '@apollo/client';
import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { CheckDocument } from '../graphql/graphql-schema';

interface ReadOnlyQuery {
    check: {
        info: {
            [entity: string]: boolean;
        };
    };
}
class ReadOnlyHandler {
    constructor(private client: ApolloClient<object>) {}

    useCheck = <TData = ReadOnlyQuery, TError = Error>(
        variables?: object,
        options?: Omit<UseQueryOptions<ReadOnlyQuery, TError, TData, QueryKey>, 'queryKey'>
    ) => {
        const queryInfo = useQuery({
            queryKey: ['readOnly', 'check'],
            queryFn: async () => {
                const result = await this.client.query<ReadOnlyQuery, object>({
                    query: CheckDocument,
                    variables,
                });
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };

    // ======> helpers
    useIsReadOnly = () => {
        return this.useCheck(undefined, {
            select: (data) => data?.check?.info,
        });
    };
}

export function createReadOnlyHandler(client: ApolloClient<object>) {
    return new ReadOnlyHandler(client);
}
