import { ApolloClient } from '@apollo/client';
import {
    CreateNewFileDocument,
    CreateNewFileMutation,
    CreateNewFileMutationVariables,
    DeleteFileDocument,
    DeleteFileMutation,
    DeleteFileMutationVariables,
    GetAllFilesDocument,
    GetAllFilesQuery,
    GetAllFilesQueryVariables,
    GetFileByIdDocument,
    GetFileByIdQuery,
    GetFileByIdQueryVariables,
    UpdateExistingFileDocument,
    UpdateExistingFileMutation,
    UpdateExistingFileMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class FilesHandler extends BaseSDKHandler<
    CreateNewFileMutation,
    CreateNewFileMutationVariables,
    GetFileByIdQuery,
    GetFileByIdQueryVariables,
    UpdateExistingFileMutation,
    UpdateExistingFileMutationVariables,
    DeleteFileMutation,
    DeleteFileMutationVariables,
    GetAllFilesQuery,
    GetAllFilesQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'files',
            CreateNewFileDocument,
            GetFileByIdDocument,
            UpdateExistingFileDocument,
            DeleteFileDocument,
            GetAllFilesDocument
        );
    }
}

export function createFilesHandler(client: ApolloClient<object>) {
    return new FilesHandler(client);
}
