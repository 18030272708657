import { ApolloClient } from '@apollo/client';
import {
    CreateNewDeliverToDocument,
    CreateNewDeliverToMutation,
    CreateNewDeliverToMutationVariables,
    DeleteDeliverToDocument,
    DeleteDeliverToMutation,
    DeleteDeliverToMutationVariables,
    FilterOptions,
    GetAllDeliverTosDocument,
    GetAllDeliverTosQuery,
    GetAllDeliverTosQueryVariables,
    GetDeliverToByIdDocument,
    GetDeliverToByIdQuery,
    GetDeliverToByIdQueryVariables,
    UpdateExistingDeliverToDocument,
    UpdateExistingDeliverToMutation,
    UpdateExistingDeliverToMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class DeliverTosHandler extends BaseSDKHandler<
    CreateNewDeliverToMutation,
    CreateNewDeliverToMutationVariables,
    GetDeliverToByIdQuery,
    GetDeliverToByIdQueryVariables,
    UpdateExistingDeliverToMutation,
    UpdateExistingDeliverToMutationVariables,
    DeleteDeliverToMutation,
    DeleteDeliverToMutationVariables,
    GetAllDeliverTosQuery,
    GetAllDeliverTosQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'DeliverTos',
            CreateNewDeliverToDocument,
            GetDeliverToByIdDocument,
            UpdateExistingDeliverToDocument,
            DeleteDeliverToDocument,
            GetAllDeliverTosDocument
        );
    }

    useDeliverTos = (
        options: FilterOptions = {
            limit: 10000,
            page: 0,
        }
    ) => {
        return this.useGetAll(
            { options },
            {
                select: (data) => data?.deliverTos,
            }
        );
    };
}

export function createDeliverTosHandler(client: ApolloClient<object>) {
    return new DeliverTosHandler(client);
}
