import { ApolloClient, ApolloQueryResult, FetchPolicy } from '@apollo/client';
import { QueryKey, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import {
    AppendDocumentColumnDocument,
    AppendDocumentColumnMutation,
    AppendDocumentColumnMutationVariables,
    BulkDeleteDocumentColumnDocument,
    BulkDeleteDocumentColumnMutation,
    BulkDeleteDocumentColumnMutationVariables,
    CreateNewDocumentDocument,
    CreateNewDocumentMutation,
    CreateNewDocumentMutationVariables,
    DeleteDocumentColumnDocument,
    DeleteDocumentColumnMutation,
    DeleteDocumentColumnMutationVariables,
    DeleteDocumentDocument,
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables,
    FilterOptions,
    GetAllDocumentsDocument,
    GetAllDocumentsQuery,
    GetAllDocumentsQueryVariables,
    GetDocumentByIdDocument,
    GetDocumentByIdQuery,
    GetDocumentByIdQueryVariables,
    GetFieldDefinitionsByTypeDocument,
    GetFieldDefinitionsByTypeQuery,
    GetFieldDefinitionsByTypeQueryVariables,
    GetMapValuesByTypeDocument,
    GetMapValuesByTypeQuery,
    GetMapValuesByTypeQueryVariables,
    GetMapValuesDocument,
    GetMapValuesQuery,
    GetMapValuesQueryVariables,
    UpdateDocumentColumnDocument,
    UpdateDocumentColumnMutation,
    UpdateDocumentColumnMutationVariables,
    UpdateDocumentSheetDocument,
    UpdateDocumentSheetMutation,
    UpdateDocumentSheetMutationVariables,
    UpdateExistingDocumentDocument,
    UpdateExistingDocumentMutation,
    UpdateExistingDocumentMutationVariables,
    UpdateSheetMetadataDocument,
    UpdateSheetMetadataMutation,
    UpdateSheetMetadataMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class DocumentsHandler extends BaseSDKHandler<
    CreateNewDocumentMutation,
    CreateNewDocumentMutationVariables,
    GetDocumentByIdQuery,
    GetDocumentByIdQueryVariables,
    UpdateExistingDocumentMutation,
    UpdateExistingDocumentMutationVariables,
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables,
    GetAllDocumentsQuery,
    GetAllDocumentsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'documents',
            CreateNewDocumentDocument,
            GetDocumentByIdDocument,
            UpdateExistingDocumentDocument,
            DeleteDocumentDocument,
            GetAllDocumentsDocument
        );
    }

    // ======> helpers
    useDocuments = (
        options: FilterOptions = {
            limit: 1000,
            page: 0,
        },
        refetchInterval?: number
    ) => {
        return this.useGetAll(
            { options },
            {
                fetchPolicy: 'network-only',
                refetchOnReconnect: true,
                refetchInterval,
                select: (data) => data?.documents,
            }
        );
    };

    useGetMapValues = (
        variables?: GetMapValuesQueryVariables,
        options?: Omit<UseQueryOptions<GetMapValuesQuery, Error, GetMapValuesQuery, QueryKey>, 'queryKey'> & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: ['productMapValues', 'get'],
            queryFn: async () => {
                const result: ApolloQueryResult<GetMapValuesQuery> = await this.client.query<
                    GetMapValuesQuery,
                    GetMapValuesQueryVariables
                >({
                    query: GetMapValuesDocument,
                    variables,
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };

    useGetMapValuesByType = (
        variables?: GetMapValuesByTypeQueryVariables,
        options?: Omit<
            UseQueryOptions<GetMapValuesByTypeQuery, Error, GetMapValuesByTypeQuery, QueryKey>,
            'queryKey'
        > & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: ['MapValues', 'get', variables?.type],
            queryFn: async () => {
                const result: ApolloQueryResult<GetMapValuesByTypeQuery> = await this.client.query<
                    GetMapValuesByTypeQuery,
                    GetMapValuesByTypeQueryVariables
                >({
                    query: GetMapValuesByTypeDocument,
                    variables,
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };

    useGetFieldDefinitionsByType = (
        variables?: GetFieldDefinitionsByTypeQueryVariables,
        options?: Omit<
            UseQueryOptions<GetFieldDefinitionsByTypeQuery, Error, GetFieldDefinitionsByTypeQuery, QueryKey>,
            'queryKey'
        > & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: ['FieldDefinitions', 'get', variables?.type],
            queryFn: async () => {
                const result: ApolloQueryResult<GetFieldDefinitionsByTypeQuery> = await this.client.query<
                    GetFieldDefinitionsByTypeQuery,
                    GetFieldDefinitionsByTypeQueryVariables
                >({
                    query: GetFieldDefinitionsByTypeDocument,
                    variables,
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };

    // ======> mutations
    useAppendDocumentColumn = () => {
        return useMutation({
            mutationFn: (variables: AppendDocumentColumnMutationVariables) => {
                return this.client.mutate<AppendDocumentColumnMutation, AppendDocumentColumnMutationVariables>({
                    mutation: AppendDocumentColumnDocument,
                    variables,
                });
            },
        });
    };

    useUpdateDocumentColumn = () => {
        return useMutation({
            mutationFn: (variables: UpdateDocumentColumnMutationVariables) => {
                return this.client.mutate<UpdateDocumentColumnMutation, UpdateDocumentColumnMutationVariables>({
                    mutation: UpdateDocumentColumnDocument,
                    variables,
                });
            },
        });
    };

    useDeleteDocumentColumn = () => {
        return useMutation({
            mutationFn: (variables: DeleteDocumentColumnMutationVariables) => {
                return this.client.mutate<DeleteDocumentColumnMutation, DeleteDocumentColumnMutationVariables>({
                    mutation: DeleteDocumentColumnDocument,
                    variables,
                });
            },
        });
    };

    useBulkDeleteDocumentColumn = () => {
        return useMutation({
            mutationFn: (variables: BulkDeleteDocumentColumnMutationVariables) => {
                return this.client.mutate<BulkDeleteDocumentColumnMutation, BulkDeleteDocumentColumnMutationVariables>({
                    mutation: BulkDeleteDocumentColumnDocument,
                    variables,
                });
            },
        });
    };

    useUpdateDocumentSheet = () => {
        return useMutation({
            mutationFn: (variables: UpdateDocumentSheetMutationVariables) => {
                return this.client.mutate<UpdateDocumentSheetMutation, UpdateDocumentSheetMutationVariables>({
                    mutation: UpdateDocumentSheetDocument,
                    variables,
                });
            },
        });
    };

    useUpdateMetadata = () => {
        return useMutation({
            mutationFn: (variables: UpdateSheetMetadataMutationVariables) => {
                return this.client.mutate<UpdateSheetMetadataMutation, UpdateSheetMetadataMutationVariables>({
                    mutation: UpdateSheetMetadataDocument,
                    variables,
                });
            },
        });
    };
}

export function createDocumentsHandler(client: ApolloClient<object>) {
    return new DocumentsHandler(client);
}
