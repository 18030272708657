import { ApolloClient } from '@apollo/client';

import {
    CreateNewSubBrandDocument,
    CreateNewSubBrandMutation,
    CreateNewSubBrandMutationVariables,
    DeleteSubBrandDocument,
    DeleteSubBrandMutation,
    DeleteSubBrandMutationVariables,
    FilterOptions,
    GetAllSubBrandsDocument,
    GetAllSubBrandsQuery,
    GetAllSubBrandsQueryVariables,
    GetSubBrandByIdDocument,
    GetSubBrandByIdQuery,
    GetSubBrandByIdQueryVariables,
    UpdateExistingSubBrandDocument,
    UpdateExistingSubBrandMutation,
    UpdateExistingSubBrandMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class SubBrandsHandler extends BaseSDKHandler<
    CreateNewSubBrandMutation,
    CreateNewSubBrandMutationVariables,
    GetSubBrandByIdQuery,
    GetSubBrandByIdQueryVariables,
    UpdateExistingSubBrandMutation,
    UpdateExistingSubBrandMutationVariables,
    DeleteSubBrandMutation,
    DeleteSubBrandMutationVariables,
    GetAllSubBrandsQuery,
    GetAllSubBrandsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'SubBrands',
            CreateNewSubBrandDocument,
            GetSubBrandByIdDocument,
            UpdateExistingSubBrandDocument,
            DeleteSubBrandDocument,
            GetAllSubBrandsDocument
        );
    }

    useSubBrands = (
        options: FilterOptions = {
            limit: 10000,
            page: 0,
        }
    ) => {
        return this.useGetAll(
            { options },
            {
                select: (data) => data?.subBrands,
            }
        );
    };
}

export function createSubBrandsHandler(client: ApolloClient<object>) {
    return new SubBrandsHandler(client);
}
