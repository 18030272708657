import { ApolloClient } from '@apollo/client';
import {
    GetAllUomsDocument,
    GetAllUomsQuery,
    GetAllUomsQueryVariables,
    GetUomDocument,
    GetUomQuery,
    GetUomQueryVariables,
} from '../graphql';
import ReadOnlyHandler from './read-only-handler';

export class UomHandler extends ReadOnlyHandler<
    GetUomQuery,
    GetUomQueryVariables,
    GetAllUomsQuery,
    GetAllUomsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(client, 'Uom', GetUomDocument, GetAllUomsDocument);
    }
}

export function createUomHandler(client: ApolloClient<object>) {
    return new UomHandler(client);
}
