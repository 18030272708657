import { ApolloClient } from '@apollo/client';
import {
    GetGpcFamiliesDocument,
    GetGpcFamiliesQuery,
    GetGpcFamiliesQueryVariables,
    GetGpcFamilyDocument,
    GetGpcFamilyQuery,
    GetGpcFamilyQueryVariables,
} from '../graphql/graphql-schema';
import ReadOnlyHandler from './read-only-handler';

class GpcFamiliesHandler extends ReadOnlyHandler<
    GetGpcFamilyQuery,
    GetGpcFamilyQueryVariables,
    GetGpcFamiliesQuery,
    GetGpcFamiliesQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(client, 'GpcFamilies', GetGpcFamilyDocument, GetGpcFamiliesDocument);
    }
}

export function createGpcFamiliesHandler(client: ApolloClient<object>) {
    return new GpcFamiliesHandler(client);
}
