import { ApolloClient } from '@apollo/client';
import {
    GetGpcBrickDocument,
    GetGpcBrickQuery,
    GetGpcBrickQueryVariables,
    GetGpcBricksDocument,
    GetGpcBricksQuery,
    GetGpcBricksQueryVariables,
} from '../graphql/graphql-schema';
import ReadOnlyHandler from './read-only-handler';

class GpcBricksHandler extends ReadOnlyHandler<
    GetGpcBrickQuery,
    GetGpcBrickQueryVariables,
    GetGpcBricksQuery,
    GetGpcBricksQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(client, 'GpcBricks', GetGpcBrickDocument, GetGpcBricksDocument);
    }
}

export function createGpcBricksHandler(client: ApolloClient<object>) {
    return new GpcBricksHandler(client);
}
