import { ApolloClient } from '@apollo/client';
import {
    GetGpcBrickAttributeValueDocument,
    GetGpcBrickAttributeValueQuery,
    GetGpcBrickAttributeValueQueryVariables,
    GetGpcBrickAttributeValuesDocument,
    GetGpcBrickAttributeValuesQuery,
    GetGpcBrickAttributeValuesQueryVariables,
} from '../graphql/graphql-schema';
import ReadOnlyHandler from './read-only-handler';

class GpcBrickAttributeValuesHandler extends ReadOnlyHandler<
    GetGpcBrickAttributeValueQuery,
    GetGpcBrickAttributeValueQueryVariables,
    GetGpcBrickAttributeValuesQuery,
    GetGpcBrickAttributeValuesQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(client, 'GpcBrickAttributeValues', GetGpcBrickAttributeValueDocument, GetGpcBrickAttributeValuesDocument);
    }
}

export function createGpcBrickAttributeValuesHandler(client: ApolloClient<object>) {
    return new GpcBrickAttributeValuesHandler(client);
}
