import { ApolloClient } from '@apollo/client';
import { useMutation } from '@tanstack/react-query';
import {
    CreateSignedImportUrlDocument,
    CreateSignedImportUrlMutation,
    CreateSignedImportUrlMutationVariables,
    StartImportDocument,
    StartImportMutation,
    StartImportMutationVariables,
} from '../graphql';

export class ImportsHandler {
    constructor(public client: ApolloClient<object>) {}

    useCreateSignedImportUrl = () =>
        useMutation({
            mutationFn: (variables: CreateSignedImportUrlMutationVariables) => {
                return this.client.mutate<CreateSignedImportUrlMutation, CreateSignedImportUrlMutationVariables>({
                    mutation: CreateSignedImportUrlDocument,
                    variables,
                });
            },
        });

    useStartImport = () =>
        useMutation({
            mutationFn: (variables: StartImportMutationVariables) => {
                return this.client.mutate<StartImportMutation, StartImportMutationVariables>({
                    mutation: StartImportDocument,
                    variables,
                });
            },
        });
}

export function createImportsHandler(client: ApolloClient<object>) {
    return new ImportsHandler(client);
}
