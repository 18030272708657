import { ApolloClient } from '@apollo/client';
import {
    CreateNewSubsidiaryDocument,
    CreateNewSubsidiaryMutation,
    CreateNewSubsidiaryMutationVariables,
    DeleteSubsidiaryDocument,
    DeleteSubsidiaryMutation,
    DeleteSubsidiaryMutationVariables,
    FilterOptions,
    GetAllSubsidiariesDocument,
    GetAllSubsidiariesQuery,
    GetAllSubsidiariesQueryVariables,
    GetSubsidiaryByIdDocument,
    GetSubsidiaryByIdQuery,
    GetSubsidiaryByIdQueryVariables,
    UpdateExistingSubsidiaryDocument,
    UpdateExistingSubsidiaryMutation,
    UpdateExistingSubsidiaryMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class SubsidiariesHandler extends BaseSDKHandler<
    CreateNewSubsidiaryMutation,
    CreateNewSubsidiaryMutationVariables,
    GetSubsidiaryByIdQuery,
    GetSubsidiaryByIdQueryVariables,
    UpdateExistingSubsidiaryMutation,
    UpdateExistingSubsidiaryMutationVariables,
    DeleteSubsidiaryMutation,
    DeleteSubsidiaryMutationVariables,
    GetAllSubsidiariesQuery,
    GetAllSubsidiariesQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'Subsidiaries',
            CreateNewSubsidiaryDocument,
            GetSubsidiaryByIdDocument,
            UpdateExistingSubsidiaryDocument,
            DeleteSubsidiaryDocument,
            GetAllSubsidiariesDocument
        );
    }

    useSubsidiaries = (
        options: FilterOptions = {
            limit: 10000,
            page: 0,
        }
    ) => {
        return this.useGetAll(
            { options },
            {
                select: (data) => data?.subsidiaries,
            }
        );
    };
}

export function createSubsidiariesHandler(client: ApolloClient<object>) {
    return new SubsidiariesHandler(client);
}
