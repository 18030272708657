import { ApolloClient } from '@apollo/client';
import {
    GetGpcClassDocument,
    GetGpcClassQuery,
    GetGpcClassQueryVariables,
    GetGpcClassesDocument,
    GetGpcClassesQuery,
    GetGpcClassesQueryVariables,
} from '../graphql/graphql-schema';
import ReadOnlyHandler from './read-only-handler';

class GpcClassesHandler extends ReadOnlyHandler<
    GetGpcClassQuery,
    GetGpcClassQueryVariables,
    GetGpcClassesQuery,
    GetGpcClassesQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(client, 'GpcClasses', GetGpcClassDocument, GetGpcClassesDocument);
    }
}

export function createGpcClassesHandler(client: ApolloClient<object>) {
    return new GpcClassesHandler(client);
}
