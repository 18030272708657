import { ApolloClient, ApolloQueryResult, FetchPolicy } from '@apollo/client';
import { QueryKey, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import {
    CreateNewIntegrationRecordDocument,
    CreateNewIntegrationRecordMutation,
    CreateNewIntegrationRecordMutationVariables,
    DeleteIntegrationRecordDocument,
    DeleteIntegrationRecordMutation,
    DeleteIntegrationRecordMutationVariables,
    GetAllIntegrationRecordsDocument,
    GetAllIntegrationRecordsQuery,
    GetAllIntegrationRecordsQueryVariables,
    GetIntegrationRecordByIdDocument,
    GetIntegrationRecordByIdQuery,
    GetIntegrationRecordByIdQueryVariables,
    GetIntegrationRecordRemoteFieldsDocument,
    GetIntegrationRecordRemoteFieldsQuery,
    GetIntegrationRecordRemoteFieldsQueryVariables,
    GetLogsByIntegrationRecordIdDocument,
    GetLogsByIntegrationRecordIdQuery,
    GetLogsByIntegrationRecordIdQueryVariables,
    UpdateExistingIntegrationRecordDocument,
    UpdateExistingIntegrationRecordMutation,
    UpdateExistingIntegrationRecordMutationVariables,
    UpdateIntegrationRecordMappingDocument,
    UpdateIntegrationRecordMappingMutation,
    UpdateIntegrationRecordMappingMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class IntegrationRecordsHandler extends BaseSDKHandler<
    CreateNewIntegrationRecordMutation,
    CreateNewIntegrationRecordMutationVariables,
    GetIntegrationRecordByIdQuery,
    GetIntegrationRecordByIdQueryVariables,
    UpdateExistingIntegrationRecordMutation,
    UpdateExistingIntegrationRecordMutationVariables,
    DeleteIntegrationRecordMutation,
    DeleteIntegrationRecordMutationVariables,
    GetAllIntegrationRecordsQuery,
    GetAllIntegrationRecordsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'integration-records',
            CreateNewIntegrationRecordDocument,
            GetIntegrationRecordByIdDocument,
            UpdateExistingIntegrationRecordDocument,
            DeleteIntegrationRecordDocument,
            GetAllIntegrationRecordsDocument
        );
    }

    // ======> helpers
    useGetLogsByIntegrationRecordId = (
        variables?: GetLogsByIntegrationRecordIdQueryVariables,
        options?: Omit<
            UseQueryOptions<GetLogsByIntegrationRecordIdQuery, Error, GetLogsByIntegrationRecordIdQuery, QueryKey>,
            'queryKey'
        > & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: ['integration-records', 'logs', variables?.integrationRecordId],
            queryFn: async () => {
                const result: ApolloQueryResult<GetLogsByIntegrationRecordIdQuery> = await this.client.query<
                    GetLogsByIntegrationRecordIdQuery,
                    GetLogsByIntegrationRecordIdQueryVariables
                >({
                    query: GetLogsByIntegrationRecordIdDocument,
                    variables,
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                if (result.errors) {
                    throw new Error(result.errors[0].message);
                }
                if (result.error) {
                    throw new Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };

    useGetRemoteFieldsByIntegrationRecordId = (
        variables?: GetIntegrationRecordRemoteFieldsQueryVariables,
        options?: Omit<
            UseQueryOptions<
                GetIntegrationRecordRemoteFieldsQuery,
                Error,
                GetIntegrationRecordRemoteFieldsQuery,
                QueryKey
            >,
            'queryKey'
        > & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: ['integration-records', 'remote-fields', variables?.id],
            queryFn: async () => {
                const result: ApolloQueryResult<GetIntegrationRecordRemoteFieldsQuery> = await this.client.query<
                    GetIntegrationRecordRemoteFieldsQuery,
                    GetIntegrationRecordRemoteFieldsQueryVariables
                >({
                    query: GetIntegrationRecordRemoteFieldsDocument,
                    variables,
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                if (result.errors) {
                    throw new Error(result.errors[0].message);
                }
                if (result.error) {
                    throw new Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };

    // ======> mutations
    useUpdateIntegrationRecordMapping = () => {
        return useMutation({
            mutationFn: (variables: UpdateIntegrationRecordMappingMutationVariables) => {
                return this.client.mutate<
                    UpdateIntegrationRecordMappingMutation,
                    UpdateIntegrationRecordMappingMutationVariables
                >({
                    mutation: UpdateIntegrationRecordMappingDocument,
                    variables,
                });
            },
        });
    };
}

export function createIntegrationRecordsHandler(client: ApolloClient<object>) {
    return new IntegrationRecordsHandler(client);
}
