import { ApolloClient } from '@apollo/client';

import { useMutation } from '@tanstack/react-query';
import {
    AddEachToPackDocument,
    AddEachToPackMutation,
    AddEachToPackMutationVariables,
    CreateNewPackDocument,
    CreateNewPackMutation,
    CreateNewPackMutationVariables,
    DeletePackDocument,
    DeletePackMutation,
    DeletePackMutationVariables,
    FilterOptions,
    GetAllPacksDocument,
    GetAllPacksQuery,
    GetAllPacksQueryVariables,
    GetPackByIdDocument,
    GetPackByIdQuery,
    GetPackByIdQueryVariables,
    UpdateExistingPackDocument,
    UpdateExistingPackMutation,
    UpdateExistingPackMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class PacksHandler extends BaseSDKHandler<
    CreateNewPackMutation,
    CreateNewPackMutationVariables,
    GetPackByIdQuery,
    GetPackByIdQueryVariables,
    UpdateExistingPackMutation,
    UpdateExistingPackMutationVariables,
    DeletePackMutation,
    DeletePackMutationVariables,
    GetAllPacksQuery,
    GetAllPacksQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'Packs',
            CreateNewPackDocument,
            GetPackByIdDocument,
            UpdateExistingPackDocument,
            DeletePackDocument,
            GetAllPacksDocument
        );
    }

    usePacks = (
        options: FilterOptions = {
            limit: 10000,
            page: 0,
        }
    ) => {
        return this.useGetAll(
            { options },
            {
                select: (data) => data?.packs,
            }
        );
    };

    useAddEachToPack = () => {
        return useMutation({
            mutationFn: (variables: AddEachToPackMutationVariables) => {
                return this.client.mutate<AddEachToPackMutation, AddEachToPackMutationVariables>({
                    mutation: AddEachToPackDocument,
                    variables,
                });
            },
        });
    };
}

export function createPacksHandler(client: ApolloClient<object>) {
    return new PacksHandler(client);
}
