import { ApolloClient, ApolloQueryResult, FetchPolicy } from '@apollo/client';
import { QueryKey, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import {
    AppendSuggestionToDocumentDocument,
    AppendSuggestionToDocumentMutation,
    AppendSuggestionToDocumentMutationVariables,
    GetMappingSuggestionsDocument,
    GetMappingSuggestionsQuery,
    GetMappingSuggestionsQueryVariables,
    RetryMappingSuggestionsDocument,
    RetryMappingSuggestionsMutation,
    RetryMappingSuggestionsMutationVariables,
} from '../graphql';

export class MappingSuggestionsHandler {
    constructor(public client: ApolloClient<object>) {}

    useGetMappings = <TData = GetMappingSuggestionsQuery, TError = Error>(
        variables: GetMappingSuggestionsQueryVariables,
        options?: Omit<UseQueryOptions<GetMappingSuggestionsQuery, TError, TData, QueryKey>, 'queryKey'> & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: ['MappingSuggestions', 'get', variables['documentId']],
            queryFn: async () => {
                const result: ApolloQueryResult<GetMappingSuggestionsQuery> = await this.client.query<
                    GetMappingSuggestionsQuery,
                    GetMappingSuggestionsQueryVariables
                >({
                    query: GetMappingSuggestionsDocument,
                    variables,
                    ...options,
                });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            ...options,
        });
        return { ...queryInfo };
    };

    useRetryMappingSuggestions = () =>
        useMutation({
            mutationFn: (variables: RetryMappingSuggestionsMutationVariables) => {
                return this.client.mutate<RetryMappingSuggestionsMutation, RetryMappingSuggestionsMutationVariables>({
                    mutation: RetryMappingSuggestionsDocument,
                    variables,
                });
            },
        });

    useAppendSuggestionToDocument = () =>
        useMutation({
            mutationFn: (variables: AppendSuggestionToDocumentMutationVariables) => {
                return this.client.mutate<
                    AppendSuggestionToDocumentMutation,
                    AppendSuggestionToDocumentMutationVariables
                >({
                    mutation: AppendSuggestionToDocumentDocument,
                    variables,
                });
            },
        });
}

export function createMappingSuggestionsHandler(client: ApolloClient<object>) {
    return new MappingSuggestionsHandler(client);
}
