import { ApolloClient, ApolloQueryResult, FetchPolicy } from '@apollo/client';
import { QueryKey, useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
    CalculateFormulaDocument,
    CalculateFormulaQuery,
    CalculateFormulaQueryVariables,
    CreateNewGeneratedDocumentDocument,
    CreateNewGeneratedDocumentMutation,
    CreateNewGeneratedDocumentMutationVariables,
    DeleteGeneratedDocumentDocument,
    DeleteGeneratedDocumentMutation,
    DeleteGeneratedDocumentMutationVariables,
    GetAllGeneratedDocumentsDocument,
    GetAllGeneratedDocumentsQuery,
    GetAllGeneratedDocumentsQueryVariables,
    GetGeneratedDocumentByIdDocument,
    GetGeneratedDocumentByIdQuery,
    GetGeneratedDocumentByIdQueryVariables,
    PatchGeneratedValuesDocument,
    RefreshGeneratedDocumentDocument,
    RefreshGeneratedDocumentMutation,
    RefreshGeneratedDocumentMutationVariables,
    UpdateExistingGeneratedDocumentDocument,
    UpdateExistingGeneratedDocumentMutation,
    UpdateExistingGeneratedDocumentMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class GeneratedDocumentsHandler extends BaseSDKHandler<
    CreateNewGeneratedDocumentMutation,
    CreateNewGeneratedDocumentMutationVariables,
    GetGeneratedDocumentByIdQuery,
    GetGeneratedDocumentByIdQueryVariables,
    UpdateExistingGeneratedDocumentMutation,
    UpdateExistingGeneratedDocumentMutationVariables,
    DeleteGeneratedDocumentMutation,
    DeleteGeneratedDocumentMutationVariables,
    GetAllGeneratedDocumentsQuery,
    GetAllGeneratedDocumentsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'generated-documents',
            CreateNewGeneratedDocumentDocument,
            GetGeneratedDocumentByIdDocument,
            UpdateExistingGeneratedDocumentDocument,
            DeleteGeneratedDocumentDocument,
            GetAllGeneratedDocumentsDocument
        );
    }

    // ======> helpers
    useGeneratedDocuments = () => {
        return this.useGetAll(undefined, {
            fetchPolicy: 'network-only',
            select: (data) => data?.generatedDocuments,
        });
    };

    useRefreshGeneratedDocument = () =>
        useMutation({
            mutationFn: (variables: RefreshGeneratedDocumentMutationVariables) => {
                return this.client.mutate<RefreshGeneratedDocumentMutation, RefreshGeneratedDocumentMutationVariables>({
                    mutation: RefreshGeneratedDocumentDocument,
                    variables,
                });
            },
        });

    usePatchGeneratedValue = () => {
        return useMutation({
            mutationFn: (variables: any) => {
                return this.client.mutate({
                    mutation: PatchGeneratedValuesDocument,
                    variables,
                });
            },
        });
    };

    useCalculateFormula = (
        variables: CalculateFormulaQueryVariables,
        options?: Omit<UseQueryOptions<CalculateFormulaQuery, Error, CalculateFormulaQuery, QueryKey>, 'queryKey'> & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: ['calculateFormula', 'get', variables.formula, variables.tradeItemId],
            queryFn: async () => {
                const result: ApolloQueryResult<CalculateFormulaQuery> = await this.client.query<
                    CalculateFormulaQuery,
                    CalculateFormulaQueryVariables
                >({
                    query: CalculateFormulaDocument,
                    variables,
                    fetchPolicy: options?.fetchPolicy || 'cache-first',
                });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };
}

export function createGeneratedDocumentsHandler(client: ApolloClient<object>) {
    return new GeneratedDocumentsHandler(client);
}
